<template>
  <div class="step-3">
    <v-container class="pa-0 mt-sm-4 mt-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-form ref="formStep3" lazy-validation>
            <v-row>
              <v-col cols="12">
                <h3 class="heading-h3">Registration</h3>
                <!-- Next Of Kin -->
                <p class="b-body-1 mt-1">
                  Step 3 of 3:
                  <span class="b-subtitle-1">Next Of Kin</span>
                </p>

                <div class="d-flex">
                  <v-card
                    color="primary"
                    width="154.67"
                    height="4"
                    elevation="0"
                  >
                  </v-card>
                  <v-card
                    class="ml-2"
                    color="primary"
                    width="154.67"
                    height="4"
                    elevation="0"
                  >
                  </v-card>
                  <v-card
                    class="ml-2"
                    color="primary"
                    width="154.67"
                    height="4"
                    elevation="0"
                  >
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" lg="4">
                <v-row>
                  <!-- Next of kin	 -->
                  <v-col cols="12">
                    <h5 class="heading-h4">Next of kin</h5>
                  </v-col>

                  <!--  Full names -->
                  <v-col cols="12">
                    <v-card class="mt-n3" max-width="362" flat>
                      <p class="label-text mb-1">Full names</p>

                      <v-text-field
                        v-model="nextOfKin.fullNames"
                        :rules="nextOfKin.fullNamesRules"
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!--  Mobile phone -->
                  <v-col cols="12">
                    <v-card class="mt-n4" max-width="362" flat>
                      <p class="label-text mb-1">Mobile phone</p>

                      <v-text-field
                        v-model="nextOfKin.mobilePhone"
                        :rules="nextOfKin.mobilePhoneRules"
                        maxlength="12"
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Relationship -->
                  <v-col cols="12">
                    <v-card class="mt-n4" max-width="362" flat>
                      <p class="label-text mb-1">Relationship</p>

                      <v-select
                        v-model="nextOfKin.relationship"
                        :rules="nextOfKin.relationshipRules"
                        :items="getRelationshipTypesList"
                        placeholder="Select..."
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      >
                      </v-select>
                    </v-card>
                  </v-col>

                  <!-- Other -->
                  <v-col cols="12">
                    <h5 class="heading-h4 mt-4">Other</h5>
                  </v-col>

                  <!-- Marital status -->
                  <v-col cols="12">
                    <v-card class="mt-n4" max-width="362" flat>
                      <p class="label-text mb-1">Marital status</p>

                      <v-select
                        v-model="other.maritalStatus"
                        :rules="other.maritalStatusRules"
                        :items="getMaritalStatusTypesList"
                        placeholder="Select..."
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      >
                      </v-select>
                    </v-card>
                  </v-col>

                 
                  <!-- Next -->
                  <!-- Desktop -->
                  <v-col cols="12" class="hidden-xs-only mt-n2">
                    <v-btn
                      @click="validateFormStep3"
                      class="b-button primary text-capitalize"
                      height="48"
                      width="362"
                      elevation="0"
                    >
                      Next
                    </v-btn>
                  </v-col>

                  <!-- Mobile -->
                  <v-col cols="12" class="hidden-sm-and-up mt-n2">
                    <v-btn
                      @click="validateFormStep3"
                      class="b-button primary text-capitalize"
                      height="48"
                      width="362"
                      elevation="0"
                    >
                      Next
                    </v-btn>
                  </v-col>


                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <!-- Overlay -->
          <v-overlay :value="overlay" z-index="1000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "LimeLoansStep3",

  data() {
    return {
      overlay: false,
      readyToSubmit: false,

      nextOfKinDetailsWasSend: false,
      maritalStatusWasSend: false,

      // Next Of Kin
      nextOfKin: {
        // Full names
        fullNames: "",
        fullNamesRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.nextOfKin.fullNamesHasError) ||
            this.nextOfKin.fullNamesErrorMessage,
          (v) =>
            /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
            "Only english letters, apostrophes and hyphens are allowed",
        ],
        fullNamesHasError: false,
        fullNamesErrorMessage: "Full names",

        // Mobile phone
        mobilePhone: "",
        mobilePhoneRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.nextOfKin.mobilePhoneHasError) ||
            this.nextOfKin.mobilePhoneErrorMessage,
          (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
          (v) => v.length == 12 || "Must be 10 numbers",
        ],
        mobilePhoneHasError: false,
        mobilePhoneErrorMessage: "",

        // Relationship
        relationship: "",
        relationshipRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.nextOfKin.relationshipHasError) ||
            this.nextOfKin.relationshipErrorMessage,
        ],
        relationshipHasError: false,
        relationshipErrorMessage: "",
      },

      // other
      other: {
        // Marital status
        maritalStatus: "",
        maritalStatusRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.other.maritalStatusHasError) ||
            this.other.maritalStatusErrorMessage,
        ],
        maritalStatusHasError: false,
        maritalStatusErrorMessage: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      getBanksList: "list/getBanksList",
      getBankAccountTypesList: "list/getBankAccountTypesList",
      getEmploymentTypesList: "list/getEmploymentTypesList",
      getDaysList: "list/getDaysList",
      getRelationshipTypesList: "list/getRelationshipTypesList",
      getMaritalStatusTypesList: "list/getMaritalStatusTypesList",
    }),
  },

  created() {
    this.$helpers.resetErrorHandlerState();
  },

  watch: {
    // nextOfKin.fullNames
    "nextOfKin.fullNames"() {
      if (this.nextOfKin.fullNamesHasError) {
        this.nextOfKin.fullNamesHasError = false;
        this.nextOfKin.fullNamesErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // nextOfKin.mobilePhone
    "nextOfKin.mobilePhone"(val) {
      if (this.nextOfKin.mobilePhoneHasError) {
        this.nextOfKin.mobilePhoneHasError = false;
        this.nextOfKin.mobilePhoneErrorMessage = "";
      }

      this.nextOfKin.mobilePhone = this.$helpers.formatInputMobileNumber(val);

      this.setReadyToSubmit();
    },

    // nextOfKin.relationship
    "nextOfKin.relationship"() {
      if (this.nextOfKin.relationshipHasError) {
        this.nextOfKin.relationshipHasError = false;
        this.nextOfKin.relationshipErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // other.maritalStatus
    "other.maritalStatus"() {
      if (this.other.maritalStatusHasError) {
        this.other.maritalStatusHasError = false;
        this.other.maritalStatusErrorMessage = "";
      }

      this.setReadyToSubmit();
    },
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    ...mapGetters({
      getIdNumber: "auth/getIdNumber",
      getSessionKey: "auth/getSessionKey",
      getSessionExpires: "auth/getSessionExpires",
      getCurrentState: "registration/getCurrentState",
    }),

    ...mapMutations({
      setLoggedIn: "auth/setLoggedIn",
      setSessionKey: "auth/setSessionKey",
      setSessionExpires: "auth/setSessionExpires",
      setUser: "auth/setUser",
      setIdNumber: "auth/setIdNumber",

      setCurrentState: "registration/setCurrentState",
    }),

    // validateFormStep3
    validateFormStep3() {
      this.$refs.formStep3.validate();

      if (this.$refs.formStep3.validate()) {
        this.nextOfKinDetailsWasSend = false;
        this.maritalStatusWasSend = false;

        if (!this.nextOfKinDetailsWasSend) {
          this.sendNextOfKinDetails();
        }

        if (!this.maritalStatusWasSend) {
          this.sendMaritalStatus();
        }
      }
    },

    // complete step 3
    completeStep3() {
      if (this.nextOfKinDetailsWasSend && this.maritalStatusWasSend) {
        this.validate();

        this.getApiUserDetails();
      }
    },

    validateFormStep3() {
      this.$refs.formStep3.validate();

      if (this.$refs.formStep3.validate()) {
        this.nextOfKinDetailsWasSend = false;
        this.maritalStatusWasSend = false;

        if (!this.nextOfKinDetailsWasSend) {
          this.sendNextOfKinDetails();
        }

        if (!this.maritalStatusWasSend) {
          this.sendMaritalStatus();
        }
      } else {
        // Show error message if form is not valid
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Please fill up all required fields.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    // setReadyToSubmit
    setReadyToSubmit() {
      if (
        this.nextOfKin.fullNames === "" ||
        this.nextOfKin.fullNames === null ||
        this.nextOfKin.mobilePhone === "" ||
        this.nextOfKin.mobilePhone === null ||
        this.nextOfKin.relationship === "" ||
        this.nextOfKin.relationship === null ||
        this.other.maritalStatus === "" ||
        this.other.maritalStatus === null ||
        !this.$refs.formStep3.validate()
      ) {
        this.readyToSubmit = false;
      } else {
        this.readyToSubmit = true;
      }
    },

    // Send Next Of Kin Details
    async sendNextOfKinDetails() {
      this.overlay = true;

      try {
        const res = await this.axios.post("next-of-kin-details", {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
          full_name: this.nextOfKin.fullNames,
          contact_number: this.nextOfKin.mobilePhone,
          relationship: this.nextOfKin.relationship,
          address: "Undisclosed",
        });

        if (res.status == 200) {
          this.nextOfKinDetailsWasSend = true;

          this.completeStep3();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }

          if ("full_name" in data) {
            this.nextOfKin.fullNamesHasError = true;
            this.nextOfKin.fullNamesErrorMessage = data.full_name[0];
          }

          if ("contact_number" in data) {
            this.nextOfKin.mobilePhoneHasError = true;
            this.nextOfKin.mobilePhoneErrorMessage = data.contact_number[0];
          }

          if ("relationship" in data) {
            this.nextOfKin.relationshipHasError = true;
            this.nextOfKin.relationshipErrorMessage = data.relationship[0];
          }

          this.$refs.formStep3.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // Send Marital Status
    async sendMaritalStatus() {
      this.overlay = true;

      try {
        const res = await this.axios.post("marital-status", {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
          marital_status: this.other.maritalStatus,
        });

        if (res.status == 200) {
          this.maritalStatusWasSend = true;

          this.completeStep3();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }

          if ("marital_status" in data) {
            this.other.maritalStatusHasError = true;
            this.other.maritalStatusErrorMessage = data.marital_status[0];
          }

          this.$refs.formStep3.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.step-3 {
  @media only screen and (max-width: 425px) {
    .b-button {
      width: 100%;
    }

    .heading-h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
    }
  }
}
</style>
